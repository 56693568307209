/* 判断账号绑定手机号码 */
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { AuthActions } from "@/redux/features/auth-slice";

export default function usePhoneNumber() {
  const dispatch = useDispatch();

  const usr_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);

  const isShowPhoneNumberAlert = () => {
    if (!usr_basic_info?.user_mobile) {
      dispatch(AuthActions?.setBindPhoneCompleteAlert(true));
      return false;
    } else {
      return true;
    }
  };

  return {
    isShowPhoneNumberAlert,
  };
}
