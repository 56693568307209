import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import Link from "next/link";
import { LazyImage } from "@/components/LazyloadImage";
import { cdnPrefix, getAvatarUrl, i18n } from "@/utils";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { AuthActions } from "@/redux/features/auth-slice";
import Reward from "./../Reward";

import { UserService } from "@/service";
import { useRouter, useSearchParams } from "next/navigation";
import usePhoneNumber from "@/hooks/phone-number";

import { StorageData } from "@/utils/net/storage/StorageData";
import { formatNumber } from "accounting";
import useAcNum from "@/hooks/acNum";
import { MyBonusActions } from "@/redux/features/my-bonus-slice";
import { countUnRead } from "@/app/notify/helper";
import Img from "@/components/Img";
import { useLoginInterceptor } from "@/components/RouterGuard/login.guard";
import { useLogReport } from "@/hooks";

import ICON_MENU_TZ from "@/assets/profile/menu/g_tz.inline.png";
import { Button } from "@/components/Button";
import { pngList } from "../png";

import "./index.css";
import { StoreKey } from "@/const";
import UserAmount from "@/components/common/UserAmount";

//pageType,1:右侧展示用户摘要信息，2：游客模式下右侧展示登录、注册按钮
export const Header = ({ pageType = 1 }: { pageType?: number }) => {
  const asset = useAppSelector((state) => state?.authSlice.asset);
  const mailData = useAppSelector((state) => state.emailSlice.mailData);
  const use_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const loginType = useAppSelector((state) => state.authSlice.loginType);
  const visible = useAppSelector((state) => state.myBonusSlice.rewardListPanelVisible);
  const isFCP = useAppSelector((state) => state.hallSlice.isFCP);

  const { isShowPhoneNumberAlert } = usePhoneNumber();
  const { refreshAcNum } = useAcNum();
  const { logReport } = useLogReport();

  const params = useSearchParams();

  const options = [
    {
      link: "/shopping",
      alt: "Depósito",
      name: i18n.home("WALLET_DEPSIT_TEXT"),
      getImg() {
        return i18n._lane === "pt" ? pngList.tubiao1 : pngList.tx_en;
      },
    },
    {
      img: pngList.tubiao2,
      link: "#",
      alt: "Retirar",
      name: i18n.home("WALLET_WITHDRAW_TEXT"),
      getImg() {
        return i18n._lane === "pt" ? pngList.tubiao2 : pngList.wx_en;
      },
    },
  ];

  const [display, setDisplay] = React.useState("none");
  const dispatch = useDispatch();
  const router = useRouter();

  const isShowPoint = useMemo(() => {
    return countUnRead(mailData!)?.all > 0;
  }, [mailData]);

  const { isGuest } = useLoginInterceptor();

  useEffect(() => {
    if (asset?.rechargecount! === 0 && StorageData.local.get(StoreKey.FIRST_CHARGE) !== "false") {
      StorageData.local.set(StoreKey.FIRST_CHARGE, true);
    }
  }, [asset]);

  const selectOnCilck = () => {
    dispatch(MyBonusActions.setRewardListPanelVisible(false));
    if (display === "none") {
      setDisplay("block");
    } else {
      setDisplay("none");
    }
  };

  // 处理大额金额字体缩放
  const amountSize = useMemo(() => {
    let allAmount = asset?.amount! + asset?.coin!;
    let amountRate = 1;
    if (allAmount < 999999) return (amountRate = 1);
    if (allAmount < 999999999) return (amountRate = 0.8);
    else return (amountRate = 0.6);
  }, [asset]);

  const profilOnCilck = () => {
    dispatch(MyBonusActions.setRewardListPanelVisible(false));
    dispatch(AuthActions.setAsideAlert(true));
    setDisplay("none");
  };

  useEffect(() => {
    if (params.get("gt_params") === "mybonus") {
      onPresetClick();
    }
  }, [params.get("gt_params")]);

  const acNum = refreshAcNum();

  const onLinkClick = (idx: number) => () => {
    if (idx === 0) {
      router.push("/shopping");
    } else if (idx === 1) {
      router.push("/withdrawal");
    } else {
      if (!isShowPhoneNumberAlert()) setDisplay("none");
      return;
    }
  };
  const onPresetClick = () => {
    setDisplay("none");
    dispatch(MyBonusActions.setRewardListPanelVisible(!visible));
  };

  const onLoginClick = () => {
    logReport("2");
  };

  const onRegisterClick = () => {
    logReport("1");
  };

  const headerRef = useRef<any>();

  return (
    <>
      <div className="homeHeader" ref={headerRef}>
        <div className="max-w-[7.68rem] m-auto flex items-center h-full bg-[#161213] relative z-100">
          <Link prefetch={true} href="/">
            <div className="logo text-center">
              <div className="w-[0.67rem]">
                <LazyImage height={"auto"} src={cdnPrefix("home/cc_yj_z1.png")} isTO={true}></LazyImage>
              </div>
            </div>
          </Link>
          {pageType === 2 && (isGuest || loginType === 0) ? (
            <>
              <div className="header-login-actions">
                <Link href={"/login?&limit=1&type=login"} prefetch={true} onClick={onLoginClick}>
                  <Button className="account-btn">{i18n.home("HOME_LOGIN")}</Button>
                </Link>
                <Link href={"/login?&limit=1&type=register"} prefetch={true} onClick={onRegisterClick}>
                  <Button className="account-btn !ml-[0.15rem]" styleType="yellow">
                    {i18n.home("HOME_REGISTAR")}
                  </Button>
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="selectBox">
                <div className="centerSelect flex items-center">
                  <div className="flex items-center" onClick={selectOnCilck}>
                    <Img
                      className="gold"
                      src={i18n._lane === "pt" ? pngList.cc_yj_jb : pngList.jb_en}
                      height={"0.5rem"}
                      alt=""
                    />
                    <p>
                      <span style={{ fontSize: amountSize + "em" }}>
                        {i18n.common("CURRENCY_SYMBOL")} &thinsp;
                        <UserAmount />
                      </span>
                    </p>
                    <Img className="arrow" src={pngList.cc_dt_sj} height={"0.56rem"} alt="" />
                  </div>
                  <Link
                    prefetch={true}
                    href="/wallet"
                    onClick={() => dispatch(MyBonusActions.setRewardListPanelVisible(false))}
                  >
                    <Img
                      className="wallet btn_animation"
                      src={pngList.cc_yj_an}
                      height={"0.66rem"}
                      isTO={true}
                      toUrl={pngList.topone_cc_yj_an}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="selectOptions" style={{ display: display }}>
                  {options?.map((val, index) => {
                    return (
                      <div className="option flex items-center" key={index} onClick={onLinkClick(index)}>
                        <Img className="optionIcon" src={val?.getImg()} alt={val?.alt} height={"0.4rem"} />
                        <p>{val?.name}</p>
                        <Img className="arrow" src={pngList.jiantou} alt="arrow" height={"0.4rem"} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="present btn_animation" onClick={() => onPresetClick()}>
                <Img src={pngList.cc_dt_lh} alt="" />
                {acNum > 0 && (
                  <span className="acnum-arrow-tip" style={{ backgroundImage: `url(${ICON_MENU_TZ})` }}>
                    {acNum}
                  </span>
                )}
              </div>
              <div className="home-user flex items-center btn_animation" onClick={profilOnCilck}>
                <Img
                  className="avatar rounded-full"
                  // src={cdnPrefix(`/profile/g_tx0${use_basic_info?.user_avatar!}.jpg`)}
                  src={getAvatarUrl(use_basic_info?.user_avatar)}
                  alt=""
                  height={"0.6rem"}
                />
                <Img className="arrow" src={pngList.cc_dt_sj} alt="" />
                {isShowPoint && <span></span>}
              </div>
            </>
          )}
        </div>
      </div>
      {isFCP && <Reward bodyDom={headerRef} />}
    </>
  );
};

export default Header;
